// @flow
import React from 'react';
// Styles
import './BigClubNotice.css';
// Components
import ProgressionBar from './ProgressionBar';
// Helpers
import { priceConverter } from '../../common';
// Types
type Props = {
  totalCartPrice: number,
  minPriceToEarnCashback: number,
};

const BigClubNotice = ({
  totalCartPrice = 0,
  minPriceToEarnCashback = 0,
}: Props) => {
  return (
    <div className="big-club-notice">
      {totalCartPrice >= minPriceToEarnCashback ? (
        <>
          <span className="big-club-notice-text">
            <span className="big-club-notice-text-orange-bold">Parabéns!</span>{' '}
            O seu<span className="big-club-notice-text-orange"> Cashback</span>{' '}
            já está disponível!
          </span>
        </>
      ) : (
        <>
          <span className="big-club-notice-text">
            Adicione mais R${' '}
            <span className="big-club-notice-text-orange">
              {priceConverter(minPriceToEarnCashback - totalCartPrice)}{' '}
            </span>
            para receber o{' '}
            <span className="big-club-notice-text-orange-bold">Cashback!</span>
          </span>
        </>
      )}
      <ProgressionBar
        absoluteValueToCompare={minPriceToEarnCashback}
        valueToBeCompared={totalCartPrice}
        barColor="linear-gradient(90deg, #CE3C25 0%, #EB5B1E 57.81%)"
      />
    </div>
  );
};

export default BigClubNotice;
