import { createSelector } from 'reselect';
// Lodash
import _ from 'lodash';
// Types
import type { StoreState } from '../types';

export const thisStateSelector = (state: any): StoreState => {
  return state.home;
};

export const bannersSelector = createSelector(thisStateSelector, state => {
  const banners = Object.values(state.banners);
  const sortedBanners = _.sortBy(banners, 'position');
  return sortedBanners;
});

export const scheduledDiscountsSelector = createSelector(
  thisStateSelector,
  state => {
    const scheduledDiscounts = Object.values(state.scheduledDiscounts);
    return scheduledDiscounts || [];
  },
);
export const scheduledDiscountsLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.scheduledDiscounts,
);

export const bannersLoadingSelector = createSelector(
  thisStateSelector,
  state => state.loading.banners,
);

export const loadingNewsLetterEmailSelector = createSelector(
  thisStateSelector,
  state => state.loading.loadingNewsLetterEmail,
);

export const themeSelector = createSelector(thisStateSelector, state => {
  const theme = state.theme;
  return theme;
});
