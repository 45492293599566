// @flow
import React, { useState, useEffect } from 'react';
// Components
import PriceInformationNotice from '../../common/components/PriceInformationNotice';
import AddToCartButton from '../containers/AddToCartButton';
import BuyPrice from '../containers/BuyPrice';
import InfoCards from '../../products/components/InfoCards';
import CookingRecommedationBox from './CookingRecommedationBox';
import OptionsSelector from '../../products/components/OptionsSelector';
// Styles
import './BuyBox.css';
// Types
import type { Product } from '../../products/types';
// Data
import { infoCards } from '../../products/data/infoCardsData';
// Assets
import QuestionMarkIcon from '../images/question-mark.svg';
import FatLevelSelector from './FatLevelSelector';
import PortionSelector from './PortionSelector';
import OptionCheckbox from './OptionCheckbox';

import type { Connector } from 'react-redux';
import { connect } from 'react-redux';
import { userLoggedInConnector } from '../../user';
import { itemSelectorByProductId } from '../selectors';
import { changeLineItem } from '../actions';

export type Props = {
  product: Product,
  item: any,
  handleEdit: (
    itemId: number,
    quantity: Number,
    observation: string,
    portioningSize: Number,
    fatLevel: 'low' | 'medium' | 'high',
    isVacuumSelected: 'vacuum' | null,
  ) => void,
};

const MINIMUM_PORTION_QUANTITY = 500;

const BuyBox = ({ product, item, handleEdit }: Props) => {
  const productOnPromotion =
    parseFloat(product.listPrice) > parseFloat(product.price);

  const [selectedFatLevel, setSelectedFatLevel] = useState('medium');
  const [eneableVacuum, setEneableVacuum] = useState(
    product.preselectedVacuumPackage,
  );
  const [eneablePortions, setEneablePortions] = useState(false);
  const [portionQuantity, setPortionQuantity] = useState(null);

  useEffect(() => {
    item &&
      handleEdit(
        item.id,
        item.quantity,
        null,
        item.portioningSize,
        !item.fatLevel ? 'medium' : selectedFatLevel,
        item.packagingName,
      );
  }, [selectedFatLevel]);

  useEffect(() => {
    item &&
      handleEdit(
        item.id,
        item.quantity,
        null,
        item.portioningSize,
        item.fatLevel,
        eneableVacuum ? 'vacuum' : null,
      );
  }, [eneableVacuum]);

  useEffect(() => {
    eneablePortions
      ? item.portioningSize
        ? setPortionQuantity(item.portioningSize)
        : setPortionQuantity(item.quantity)
      : setPortionQuantity(null);
  }, [eneablePortions]);

  useEffect(() => {
    if (item) {
      !portionQuantity
        ? handleEdit(
            item.id,
            item.quantity,
            null,
            item.quantity,
            item.fatLevel,
            item.packagingName,
          )
        : portionQuantity >= MINIMUM_PORTION_QUANTITY &&
          portionQuantity <= item.quantity &&
          handleEdit(
            item.id,
            item.quantity,
            null,
            portionQuantity,
            item.fatLevel,
            item.packagingName,
          );
    }
  }, [portionQuantity]);

  useEffect(() => {
    if (item) {
      setSelectedFatLevel(!item.fatLevel ? 'medium' : item.fatLevel);
      setEneableVacuum(
        item.packagingName ? true : product.preselectedVacuumPackage,
      );
      setEneablePortions(
        item.portioningSize && item.portioningSize !== item.quantity
          ? true
          : false,
      );
      setPortionQuantity(item.portioningSize ? item.portioningSize : null);
    }
  }, [item && item.id]);

  useEffect(() => {
    setEneablePortions(false);
  }, [item && item.quantity]);

  return (
    <div className="buy-box-main">
      <div className="buy-box-title">
        <p className="buy-box-manufacturer">{product.manufacturer}</p>
        <h1 className="buy-box-product-name">{product.name}</h1>
      </div>
      <section className="buy-box-section">
        <CookingRecommedationBox
          cookingMethods={product.cookingRecommendations}
        />
        <OptionsSelector
          options={product.options}
          productPrice={product.price}
          productUnit={product.unitForQuantity}
        />

        {item && (
          <>
            {product.fatLevelEnabled && (
              <FatLevelSelector
                fatLevel={selectedFatLevel}
                updateFatLevel={setSelectedFatLevel}
              />
            )}
            {product.unitForQuantity === 'g' && (
              <div className="option-checkbox-container">
                {product.vacuumPackable && (
                  <OptionCheckbox
                    tooltipLabel={'Embalagem a vácuo?'}
                    tooltipText={
                      'Carne embalada a vácuo: mais frescor, sabor e durabilidade! Qualidade garantida. Experimente!'
                    }
                    tooltipPlacement={'bottom'}
                    clickHandler={setEneableVacuum}
                    value={eneableVacuum}
                    defaultValue={eneableVacuum}
                  />
                )}
                <OptionCheckbox
                  tooltipLabel={'Porcionar?'}
                  tooltipText={
                    'O porcionamento é feito para que você possa ter o seu produto separado do seu jeito!'
                  }
                  tooltipPlacement={'bottom'}
                  clickHandler={setEneablePortions}
                  value={eneablePortions}
                  defaultValue={eneablePortions}
                />
              </div>
            )}

            {product.unitForQuantity === 'g' && eneablePortions && (
              <PortionSelector
                productId={product.id}
                quantity={item.quantity}
                portionQuantity={portionQuantity}
                updatePortionQuantity={setPortionQuantity}
              />
            )}
          </>
        )}

        <InfoCards cards={infoCards} />
        <div className="buy-box-section-price-container">
          <div className="buy-box-section-price-container-action">
            <AddToCartButton
              showNotifyMeButton={true}
              productId={product.id}
              slug={product.slug}
              unitForQuantity={product.unitForQuantity}
              defaultQuantity={product.defaultQuantity}
              incrementQuantity={product.incrementQuantity}
              inStock={product.inStock}
              discontinued={product.discontinued}
              shouldNotifyUser={product.shouldNotifyUser}
              analyticsEventType={'visit'} // USE ANALYTICS
              eneableFatLevel={product.fatLevelEnabled}
              eneablePackagingName={product.vacuumPackable}
              fatLevel={item && item.fatLevel}
              packagingName={item && item.packagingName}
            />

            {product.unitForQuantity !== 'un' && (
              <p className="buy-box-min-quantity">
                Quantidade mínima: &nbsp;
                {product.unitForQuantity === 'g'
                  ? (product.minimumQuantity / 1000).toLocaleString('pt-br', {
                      currency: 'BRL',
                    }) + ' Kg'
                  : product.minimumQuantity}
              </p>
            )}
          </div>
          <div className="buy-box-sub-container">
            <p className="buy-box-sub-container-title">Preço Estimado</p>
            <div className="buy-box-sub-container-wrapper">
              <img
                src={QuestionMarkIcon}
                alt="entenda o preco"
                className="buy-box-question-mark"
              />
              <div className="buy-box-understand-the-price">
                Entenda o preço
              </div>
              <div className="buy-box-price-content">
                <PriceInformationNotice />
              </div>
            </div>

            <BuyPrice
              productId={product.id}
              listPrice={product.listPrice}
              price={product.price}
              unit={product.unitForQuantity}
              showPromo={productOnPromotion}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state, props: Props) => {
  const item = itemSelectorByProductId(state, props.product.id);
  return {
    item: item,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  return {
    handleEdit: (
      itemId: number,
      quantity: Number,
      observation: string,
      portioningSize: number,
      fatLevel: 'low' | 'medium' | 'high',
      packagingName: 'vacuum' | null,
    ) => {
      dispatch(
        changeLineItem(
          itemId,
          quantity,
          observation,
          portioningSize,
          fatLevel,
          packagingName,
        ),
      );
    },
  };
};

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default userLoggedInConnector(connector(BuyBox));
