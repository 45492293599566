import React from 'react';

import './FatLevelSelector.css';
import FatLevelOption from './FatLevelOption';

type Props = {
  fatLevel: 'low' | 'medium' | 'high',
  updateFatLevel: () => void,
};

const FatLevelSelector = ({fatLevel, updateFatLevel}: Props) => {

  return (
    <div className="fat-level-selector-container">
      <FatLevelOption
        optionTile="high"
        selectedFatLevel={fatLevel}
        clickHandler={updateFatLevel}
      />
      <FatLevelOption
        optionTile="medium"
        selectedFatLevel={fatLevel}
        clickHandler={updateFatLevel}
      />
      <FatLevelOption
        optionTile="low"
        selectedFatLevel={fatLevel}
        clickHandler={updateFatLevel}
      />
    </div>
  );
};

export default FatLevelSelector;
