import React from 'react';

import './FatLevelSelector.css';

// Fat level enum
const fatLevel = Object.freeze({
  low: 'Magro',
  medium: 'Padrão',
  high: 'Gordo',
});

type Props = {
  selectedFatLevel: 'low' | 'medium' | 'high',
  optionTile: 'low' | 'medium' | 'high',
  clickHandler: () => void,
};

const FatLevelOption = (props: Props) => {
  return (
    <div
      onClick={() => props.clickHandler(props.optionTile)}
      className={`fat-level-option-container ${
        props.selectedFatLevel === props.optionTile && 'selected'
      }`}
    >
      <p
        className={`fat-level-option-title ${
          props.selectedFatLevel === props.optionTile && 'selected'
        }`}
      >
        {fatLevel[props.optionTile]}
      </p>
    </div>
  );
};

export default FatLevelOption;
