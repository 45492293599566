// @flow
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './CartResumePreview.css';
// Helpers
import { priceConverter } from '../../common';
// Analytics
import * as Analytics from '../../common/analytics';
// Assets
import OrderIcon from '../images/order-icon.svg';
import { jsConfetti } from '../../App';

type Props = {
  totalPrice: string,
  hasProductsDiscontinuedOrOutOfStock: boolean,
  items: LineItem[],
};

const CartResumePreview = (props: Props) => {
  return (
    <section className="cart-resume-preview-container">
      <div className="cart-resume-preview-header">
        <img src={OrderIcon} alt="OrderIcon"></img>
        <h3 className="cart-resume-preview-title">Resumo do pedido</h3>
      </div>
      <div className="cart-resume-preview-info">
        <p className="cart-resume-preview-info-text">Itens</p>
        <span className="cart-resume-preview-info-text-alt">
          {priceConverter(props.totalPrice)}
        </span>
      </div>
      <hr className="cart-resume-preview-horizontal-rule"></hr>
      <div className="cart-resume-previewinfo">
        <p className="cart-resume-preview-text">Total:</p>
        <span className="cart-resume-preview-info-text-alt cart-resume-preview-info-text-orange">
          {priceConverter(parseFloat(Math.abs(props.totalPrice)))}
        </span>
      </div>
      <div
        className={`cart-resume-preview-button ${
          props.hasProductsDiscontinuedOrOutOfStock
            ? 'cart-list-button-disabled'
            : ''
        }`}
        onClick={() => {
          Analytics.logTappedCheckout(props.items);
          jsConfetti.addConfetti();
        }}
      >
        <Link
          to="/checkout/"
          className={`cart-resume-preview-btn ${
            props.hasProductsDiscontinuedOrOutOfStock
              ? 'cart-resume-preview-btn-disabled'
              : ''
          }`}
        >
          FECHAR COMPRA
        </Link>
      </div>
    </section>
  );
};

export default CartResumePreview;
