import React from 'react';

import './PortionSelector.css';

type Props = {
  productId: number,
  quantity: Number,
  portionQuantity: Number,
  updatePortionQuantity: () => void,
};

const PortionSelector = ({
  productId,
  quantity,
  portionQuantity,
  updatePortionQuantity,
}: Props) => {
  const minQuantity = 1;
  const maxQuantity = Math.ceil(quantity / 500);

  const handlePortionButtonChange = value => {
    if (Number(portionQuantity) + value < 500) {
      updatePortionQuantity(500);
    } else if (Number(portionQuantity) + value >= quantity) {
      updatePortionQuantity(quantity);
    } else {
      updatePortionQuantity(quantity => Number(quantity) + Number(value));
    }
  };

  const handlePortionInputChange = value => {
    if (value < 500) {
      updatePortionQuantity(500);
    } else if (value >= quantity) {
      updatePortionQuantity(quantity);
    } else {
      updatePortionQuantity(value);
    }
  };

  const handlePackagesInputChange = amount => {
    if (amount <= minQuantity) {
      return minQuantity;
    }
    if (amount >= maxQuantity) {
      return maxQuantity;
    }
    return amount;
  };

  return (
    <div className="portion-selector-container">
      <p>
        <strong>
          {handlePackagesInputChange(
            Math.ceil(quantity / (portionQuantity > 0 ? portionQuantity : 1)),
          )}
        </strong>
      </p>
      <p>Embalagens de</p>
      <div className="quantity-input-container">
        <div className="quantity-input-inner-container">
          <input
            className="quantity-input"
            type="number"
            value={portionQuantity}
            onChange={e => {
              updatePortionQuantity(e.target.value);
            }}
            onBlur={e => handlePortionInputChange(e.target.value)}
          />
          <p className="quantity-input-unity">g</p>
        </div>
        <div className="quantity-control-container">
          <button
            className="quantity-control"
            onClick={() => handlePortionButtonChange(+25)}
          >
            <span style={{ color: 'white' }}>
              <i className="fa fa-sharp fa-light fa-plus"></i>
            </span>
          </button>
          <button
            className="quantity-control"
            onClick={() => handlePortionButtonChange(-25)}
          >
            <span style={{ color: 'white' }}>
              <i className="fa fa-sharp fa-light fa-minus"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PortionSelector;
