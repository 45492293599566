// @flow
import React, { Component } from 'react';
// Components
import ProgressionBar from '../../current-order/components/ProgressionBar';
// helpers
import { priceConverter } from '../../common';
// Styles
import './FreeShippingNotice.css';

type Props = {
  currentPrice: number,
  shouldDisplay: boolean,
};

class FreeShippingNotice extends Component<Props> {
  render() {
    const { shouldDisplay, currentPrice } = this.props;
    if (!shouldDisplay) return null;

    return (
      <div className="free-shipping-notice-main">
        {currentPrice >= 150 ? (
          <>
            <span className="free-shipping-notice-text">
              <span className="free-shipping-notice-main-green">Parabéns!</span>{' '}
              O seu{' '}
              <span className="free-shipping-notice-main-green">
                Frete grátis
              </span>{' '}
              agendado já está disponível!
            </span>
          </>
        ) : (
          <>
            <span className="free-shipping-notice-text">
              Adicione mais R${' '}
              <span className="free-shipping-notice-main-green">
                {priceConverter(150 - currentPrice)}{' '}
              </span>{' '}
              para receber{' '}
              <span className="free-shipping-notice-main-green">
                Frete Gátis
              </span>
              , para entregas agendadas!
            </span>
          </>
        )}
        <ProgressionBar
          absoluteValueToCompare={150}
          valueToBeCompared={currentPrice}
          barColor="#1C8E47"
        />
      </div>
    );
  }
}

export default FreeShippingNotice;
